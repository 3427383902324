import { useHistory } from "react-router-dom";
import { useTypedDispatch } from "../../redux/store";
import { setCurrentUserAction } from "../../redux/users/usersActions";

import "./styles.css";

export interface IUserItemProps {
  id?: number;
  birth_year: string;
  eye_color: string;
  films: string[];
  gender: string;
  hair_color: string;
  height: string;
  homeworld: string;
  mass: string;
  name: string;
  skin_color: string;
  created: string;
  edited: string;
  species: string[];
  starships: string[];
  url: string;
  vehicles: string[];
}

interface UserProps {
  user: IUserItemProps;
}

export const UserItem = ({ user }: UserProps) => {
  const history = useHistory();
  const userId = user.url.slice(-3).replaceAll("/", "");
  const dispatch = useTypedDispatch();

  const onUserClick = () => {
    history.push(`/user/${userId}`);

    dispatch(setCurrentUserAction(user, +userId));
  };

  return (
    <li className="userItem" onClick={onUserClick}>
      {user.name}
    </li>
  );
};
