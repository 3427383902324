import { TextField } from "@mui/material";
import { ChangeEvent, SyntheticEvent, forwardRef, useState } from "react";

interface InputProps {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  onBlur: (e: SyntheticEvent) => void;
  className?: string;
  disabled?: boolean;
}

export const Input = forwardRef(
  (
    { label, placeholder, value, name, onBlur, onChange, disabled }: InputProps,
    ref
  ) => {
    const [val, setVal] = useState(value);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setVal(e.target.value);
      onChange(e);
    };
    return (
      <TextField
        label={label}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        inputRef={ref}
        value={val}
        onChange={handleChange}
        onBlur={onBlur}
      />
    );
  }
);
