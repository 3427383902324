import { BrowserRouter, Switch, Route } from "react-router-dom";
import { UsersList } from "./components/UsersList/UsersList";
import CssBaseline from "@mui/material/CssBaseline";
import { UserPage } from "./components/UserPage/UserPage";

function App() {
  return (
    <BrowserRouter>
      <CssBaseline />
      <Switch>
        <Route exact path="/">
          <UsersList />
        </Route>
        <Route exact path="/user/:id">
          <UserPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
