import { ChangeEvent, useEffect, useState } from "react";
import { IUserItemProps, UserItem } from "../UserItem/UserItem";
import { Pagination, TextField } from "@mui/material";
import { api } from "../../api/api";
import { apiRoutes } from "../../api/api.routes";
import { useTypedDispatch, useTypedSelector } from "../../redux/store";
import {
  addUsersByPageAction,
  setCurrenPageAction,
  setUsersCountAction,
} from "../../redux/users/usersActions";

import "./styles.css";

export const UsersList = () => {
  const users = useTypedSelector((state) => state.usersByPage);

  const [userName, setUserName] = useState("");
  const [searchedUsers, setSearchedUsers] = useState<IUserItemProps[] | []>([]);
  const dispatch = useTypedDispatch();

  const { currentPage, usersCount } = useTypedSelector(
    (state) => state.pagination
  );

  const PAGINATION_COUNT = Math.ceil(usersCount / 10);

  useEffect(() => {
    if (users.length === 0) {
      api
        .get(apiRoutes.allPeople())
        .then((res) => {
          if (res.status === 200) {
            dispatch(setUsersCountAction(res.data.count));
            dispatch(addUsersByPageAction(res.data.results));
          }
        })
        .catch((err) => {
          console.log("Get users from first page", err);
        });
    }
  }, [users.length, dispatch]);

  useEffect(() => {
    if (userName.trim().length > 0) {
      api
        .get(apiRoutes.searchUserByName(userName.trim()))
        .then((res) => {
          if (res.status === 200) {
            setSearchedUsers(res.data.results);
          }
        })
        .catch((err) => {
          console.log("Search user error", err);
        });
    }
  }, [userName]);

  const handleChangeUserName = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    dispatch(setCurrenPageAction(page));
    api
      .get(apiRoutes.peoplesByPage(page))
      .then((res) => {
        if (res.status === 200) {
          dispatch(addUsersByPageAction(res.data.results));
        }
      })
      .catch((err) => {
        console.log(`Get users by page: ${page}`, err);
      });
  };

  return (
    <section className="usersList">
      <TextField
        placeholder="Enter user name"
        value={userName}
        onChange={handleChangeUserName}
      />
      {userName.length > 0 && searchedUsers.length === 0 ? (
        <h1>No results</h1>
      ) : (
        <>
          {!users.length ? (
            <h2>Loading...</h2>
          ) : (
            <>
              <ul>
                {(userName.length > 0 ? searchedUsers : users)?.map((item) => {
                  return <UserItem key={item.name} user={{ ...item }} />;
                })}
              </ul>
              <Pagination
                color="primary"
                count={PAGINATION_COUNT}
                page={currentPage}
                onChange={onPageChange}
              />
            </>
          )}
        </>
      )}
    </section>
  );
};
