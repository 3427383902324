import { Dispatch } from "react";
import { IUserItemProps } from "../../components/UserItem/UserItem";
import { RootState } from "../store";
import { IInitialUsersState } from "./users.interfaces";
import { UsersActions, updateUserByNameAction } from "./usersActions";

const initialUsersState: IInitialUsersState = {
  usersByPage: [],
  currentUser: {} as IUserItemProps,
  pagination: {
    currentPage: 1,
    usersCount: 0,
  },
};

export const usersReducer = (
  state = initialUsersState,
  action: UsersActions
): IInitialUsersState => {
  switch (action.type) {
    case "users/addUsersByPageAction":
      return {
        ...state,
        usersByPage: action.payload,
      };
    case "users/setCurrentUserAction":
      return {
        ...state,
        currentUser: { ...action.payload.user, id: action.payload.userId },
      };
    case "users/setCurrentPageAction":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload,
        },
      };
    case "users/setUsersCountAction":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          usersCount: action.payload,
        },
      };
    case "users/updateUserByNameAction":
      return {
        ...state,
        usersByPage: action.payload,
      };
    default:
      return state;
  }
};

export const updateUserByNameThunk =
  (user: IUserItemProps) =>
  (dispatch: Dispatch<UsersActions>, getState: () => RootState) => {
    const { usersByPage } = getState();
    const currentUserIndex = usersByPage.findIndex((u) => u.id === user.id);
    const usersToSave = [...usersByPage];
    usersToSave[currentUserIndex] = user;

    dispatch(updateUserByNameAction(usersToSave));
  };
