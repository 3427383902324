import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../api/api";
import { apiRoutes } from "../../api/api.routes";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { useTypedDispatch, useTypedSelector } from "../../redux/store";
import { IUserItemProps } from "../UserItem/UserItem";
import { updateUserByNameThunk } from "../../redux/users/usersReducer";
import {
  addUsersByPageAction,
  setCurrentUserAction,
} from "../../redux/users/usersActions";
import { Input } from "../Input/Input";

import "./styles.css";

export const UserPage = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useTypedDispatch();
  const history = useHistory();
  const {
    pagination: { currentPage },
    currentUser,
  } = useTypedSelector((state) => state);

  useEffect(() => {
    if (!currentUser.name) {
      api
        .get(apiRoutes.peopleById(id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setCurrentUserAction(res.data, +id));
          }
        })
        .catch((err) => {
          console.log("Get user by id", err);
        });

      api
        .get(apiRoutes.peoplesByPage(currentPage))
        .then((res) => {
          if (res.status === 200) {
            dispatch(addUsersByPageAction(res.data.results));
          }
        })
        .catch((err) => {
          console.log(`Get users from page: ${currentPage}`, err);
        });
    }
  }, [dispatch, id, currentPage, currentUser]);

  const {
    name,
    height,
    mass,
    hair_color,
    birth_year,
    created,
    edited,
    eye_color,
    films,
    gender,
    homeworld,
    skin_color,
    species,
    starships,
    url,
    vehicles,
  } = currentUser;

  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: name,
      birth_year: birth_year,
      eye_color: eye_color,
      films: films,
      gender: gender,
      hair_color: hair_color,
      height: height,
      homeworld: homeworld,
      mass: mass,
      skin_color: skin_color,
      created: created,
      edited: edited,
      species: species,
      starships: starships,
      url: url,
      vehicles: vehicles,
    },
  });

  const onFormSubmit = (data: IUserItemProps) => {
    dispatch(updateUserByNameThunk(data));
    dispatch(setCurrentUserAction(data, +id));
  };

  const onUsersListClick = () => {
    history.push("/");
  };

  if (!currentUser.name) {
    return <h1>Loading...</h1>;
  }

  return (
    <section className="userPage">
      <header className="header">
        <Button variant="contained" onClick={onUsersListClick}>
          Go to users list
        </Button>
      </header>

      {currentUser.name && (
        <form className="form" onSubmit={handleSubmit(onFormSubmit)}>
          <Input
            {...register("name")}
            value={name}
            label="name"
            placeholder="name"
          />
          <Input
            {...register("height")}
            value={height}
            label="height"
            placeholder="height"
          />
          <Input
            {...register("mass")}
            value={mass}
            label="mass"
            placeholder="mass"
          />
          <Input
            {...register("hair_color")}
            value={hair_color}
            label="hair_color"
            placeholder="hair_color"
          />
          <Input
            {...register("skin_color")}
            value={skin_color}
            label="skin_color"
            placeholder="skin_color"
          />
          <Input
            {...register("eye_color")}
            value={eye_color}
            label="eye_color"
            placeholder="eye_color"
          />
          <Input
            {...register("birth_year")}
            value={birth_year}
            label="birth_year"
            placeholder="birth_year"
          />
          <Input
            {...register("gender")}
            value={gender}
            label="gender"
            placeholder="gender"
          />
          <Input
            {...register("homeworld")}
            value={homeworld}
            label="homeworld"
            placeholder="homeworld"
          />
          <Input
            {...register("films")}
            value={String(films)}
            label="films"
            placeholder="films"
          />
          <Input
            {...register("species")}
            value={String(species)}
            label="species"
            placeholder="species"
          />
          <Input
            {...register("vehicles")}
            value={String(vehicles)}
            label="vehicles"
            placeholder="vehicles"
          />
          <Input
            {...register("starships")}
            value={String(starships)}
            label="starships"
            placeholder="starships"
          />
          <Input
            {...register("created")}
            value={created}
            label="created"
            placeholder="created"
          />
          <Input
            {...register("edited")}
            value={edited}
            label="edited"
            placeholder="edited"
          />
          <Input
            {...register("url")}
            value={url}
            label="url"
            placeholder="url"
          />
          <Button type="submit" variant="contained">
            Save
          </Button>
        </form>
      )}
    </section>
  );
};
