import { createStore, applyMiddleware, AnyAction } from "redux";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import thunk, { ThunkDispatch } from "redux-thunk";
import { usersReducer } from "./users/usersReducer";

export const store = createStore(usersReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof store.getState>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export const useTypedDispatch = () => useDispatch<TypedDispatch>();
