import { IUserItemProps } from "../../components/UserItem/UserItem";

interface IAddUsersByPageAction {
  type: "users/addUsersByPageAction";
  payload: IUserItemProps[];
}

interface IAddUserAction {
  type: "users/setCurrentUserAction";
  payload: {
    user: IUserItemProps;
    userId: number;
  };
}

interface ISetCurrentPageAction {
  type: "users/setCurrentPageAction";
  payload: number;
}

interface ISetUsersCountAction {
  type: "users/setUsersCountAction";
  payload: number;
}

interface IUpdateUserByNameAction {
  type: "users/updateUserByNameAction";
  payload: IUserItemProps[];
}

export type UsersActions =
  | IAddUsersByPageAction
  | IAddUserAction
  | ISetCurrentPageAction
  | ISetUsersCountAction
  | IUpdateUserByNameAction;

export const addUsersByPageAction = (
  users: IUserItemProps[]
): IAddUsersByPageAction => ({
  type: "users/addUsersByPageAction",
  payload: users,
});

export const setCurrentUserAction = (
  user: IUserItemProps,
  userId: number
): IAddUserAction => ({
  type: "users/setCurrentUserAction",
  payload: { user: user, userId: userId },
});

export const setCurrenPageAction = (
  currentPage: number
): ISetCurrentPageAction => ({
  type: "users/setCurrentPageAction",
  payload: currentPage,
});

export const setUsersCountAction = (
  currentPage: number
): ISetUsersCountAction => ({
  type: "users/setUsersCountAction",
  payload: currentPage,
});

export const updateUserByNameAction = (
  users: IUserItemProps[]
): IUpdateUserByNameAction => ({
  type: "users/updateUserByNameAction",
  payload: users,
});
